<template>
  <!-- 作业师傅管理 -->
  <div class="container">
    <a-tabs :default-active-key="tabIndex" :animated="false" @change="callback">
      <a-tab-pane v-for="item in tabs" :key="item.key" :tab="item.name">
        <div class="main-content">
          <div class="ribbon-wrap">
            <div class="item">
              <div class="search-box">
                <span class="label">模糊搜索：</span>
                <a-input
                  class="search-input"
                  v-model="searchValue"
                  placeholder="尝试搜索姓名、手机号等"
                />
              </div>
            </div>
            <div class="item">
              <div class="btn-box" v-permission="'search'">
                <a-button @click="searchReset"> 重置 </a-button>
                <a-button type="primary" @click="searchSubmit"> 查询 </a-button>
              </div>
            </div>
          </div>

          <div class="main-content-header">
            <div class="item">
              <div class="title">{{ item.name }}列表</div>
            </div>
            <div class="item">
              <a-button v-permission="'export'"> 导出师傅花名册 </a-button>
            </div>
          </div>

          <my-table
            :columns="item.columns"
            :data-source="item.dataSource"
            :pagination="item.pagination"
            :loading="item.loading"
            :scroll="{ x: item.x, y: true }"
            @change="item.paginationChange"
          >
            <template slot="type" slot-scope="record">
              {{ levels[record.level] }}
            </template>
            <template slot="status" slot-scope="record">
              <a-badge
                :status="statuText[record.status].status"
                :text="statuText[record.status].text"
              />
            </template>
            <template slot="servicerType" slot-scope="record">
               {{ PointsList[record.servicerType] }}
            </template>
            <template slot="action" slot-scope="record">
              <a-button type="link">
                <router-link
                  :to="{
                    path: '/masterInfo',
                    query: { id: record.id }
                  }"
                >
                  更多详情
                </router-link>
              </a-button>
              <a-dropdown placement="bottomCenter">
                <a-icon type="ellipsis" />
                <a-menu slot="overlay">
                  <a-menu-item :key="0" v-permission="'edit'">
                    <router-link
                      :to="{
                        path: '/',
                        query: { id: record.id }
                      }"
                    >
                      编辑资料
                    </router-link>
                  </a-menu-item>
                  <a-menu-item
                    :key="1"
                    @click="setStatus(record.id, record.status)"
                  >
                    <span v-if="record.status === 1">离编</span>
                    <span v-else>在编</span>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </template>
          </my-table>
        </div>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabs: [
        {
          key: 0,
          type: 1,
          name: '服务师傅',
          dataSource: [],
          x: 1000,
          columns: [
            {
              title: '序号',
              width: 100,
              customRender: (text, record, index) => index + 1
            },
            {
              title: '师傅姓名',
              width: 150,
              dataIndex: 'realName'
            },
            {
              title: '师傅联系方式',
              width: 200,
              dataIndex: 'mobile'
            },
            {
              title: '师傅工号',
              width: 150,
              dataIndex: 'jobNumber'
            },
            {
              title: '师傅类型',
              width: 150,
              scopedSlots: { customRender: 'type' }
            },
            {
              title: '师傅状态',
              scopedSlots: { customRender: 'status' }
            },
            {
              title: '师傅所属',
              scopedSlots: { customRender: 'servicerType' }
            },
            {
              title: '操作',
              width: 150,
              fixed: 'right',
              scopedSlots: {
                customRender: 'action'
              }
            }
          ],
          loading: true,
          pagination: {
            current: 1,
            total: 0
          },
          paginationChange: this.handlePaginationChange
        },
        {
          key: 1,
          type: 2,
          name: '带训师傅',
          dataSource: [],
          x: 1100,
          columns: [
            {
              title: '序号',
              width: 100,
              customRender: (text, record, index) => index + 1
            },
            {
              title: '师傅姓名',
              width: 150,
              dataIndex: 'realName'
            },
            {
              title: '师傅联系方式',
              width: 200,
              dataIndex: 'mobile'
            },
            {
              title: '师傅工号',
              width: 150,
              dataIndex: 'jobNumber'
            },
            {
              title: '师傅类型',
              width: 150,
              scopedSlots: { customRender: 'type' }
            },
            {
              title: '学徒数量',
              width: 100,
              dataIndex: ''
            },
            {
              title: '师傅平台',
              scopedSlots: { customRender: 'status' }
            },
            {
              title: '操作',
              width: 150,
              fixed: 'right',
              scopedSlots: {
                customRender: 'action'
              }
            }
          ],
          loading: true,
          pagination: {
            current: 1,
            total: 0
          },
          paginationChange: this.handlePaginationChange
        }
      ],
      tabIndex: 0,
      searchValue: '',
      statuText: [
        {
          status: 'default',
          text: '离编'
        },
        {
          status: 'success',
          text: '在编'
        }
      ],
      levels: {
        1: '服务师傅',
        2: '带训师傅'
      },
       PointsList: {
        1: '平台师傅',
        2: '外请师傅',
        3: '运营点归属的师傅'
      },
    }
  },
  created() {
    this.getMaster()
  },
  methods: {
    callback(key) {
      // tab切换
      this.tabIndex = key
      this.getMaster()
    },
    searchReset() {
      // 重置搜索
      this.searchValue = ''
      this.getMaster()
    },
    searchSubmit() {
      // 搜索
      if (!this.searchValue) return
      const i = this.tabIndex
      this.tabs[i].pagination.current = 1
      this.tabs[i].loading = true
      this.getMaster()
    },
    handlePaginationChange(e) {
      const i = this.tabIndex
      this.tabs[i].loading = true
      this.tabs[i].pagination.current = e.current
      this.getMaster()
    },
    getMaster() {
      // 获取列表
      const i = this.tabIndex
      const data = {
        page: this.tabs[i].pagination.current,
        limit: 10,
        level: this.tabs[i].type
      }
      if (this.searchValue) {
        this.tabs[i].loading = true
        data.keyword = this.searchValue
        data.page = 1
        this.tabs[i].pagination.current = 1
      }
      this.$axios.getMaster(data).then((res) => {
        this.tabs[i].dataSource = res.data.data.list
        this.tabs[i].pagination.total = res.data.data.count
        this.tabs[i].loading = false
      })
    },
    setStatus(id, status) {
      // 设置状态
      let title = ''
      if (status === 1) {
        title = '确定设为离编？'
        status = 0
      } else {
        title = '确定设为在编？'
        status = 1
      }
      this.$confirm({
        title: title,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          // const data = {
          //   id,
          //   status
          // }
          // this.$axios.setApprenticeStatus(data).then(() => {
          //   this.$message.success('操作成功')
          //   this.getApprentice()
          // })
        }
      })
    }
  }
}
</script>

<style scoped></style>
